.navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 0px;
    margin-right: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    /* justify-content: end; */
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transform: translate(25%, 50%);
              transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      -webkit-transform: translate(-100%, 60%);
              transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }
  
:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: #fff;
    background-color: var(--primary);
    color: #242424;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover {
    background: #fff;
    color: #242424;
    transition: all 0.3s ease-out;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.projects,
.about,
.experience,
.resume {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.projects {
  background-image: url(/static/media/project-background.57db15d8.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.experience {
  background-image: url(/static/media/job.fe68a357.webp);
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.projects > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}

.about {
  background-image: url(/static/media/about-back.0e48e88b.jpeg);
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.resume {
  background-image: url(/static/media/san-fran.2f3b8e95.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
  .cards {
    padding: 4rem;
    background: #fff;
  }
  
  .bio {
    /* color: #6F4117; */
    text-align: center;
    font-size: 25px;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
  }
  
  h1 {
    text-align: center;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1 1;
    margin: 0 1rem;
    border-radius: 10px;
    white-space: pre-wrap;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    -webkit-animation-name: fade-img;
            animation-name: fade-img;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #636665;
    font-size: 18px;
    line-height: 24px;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }
video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.hero-container {
    background: ('/images/img-home.jpg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
}

.hero-container  > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
}

.hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;
}

@media screen and (max-width: 960px) {
    .hero-container  > h1 {
        font-size: 70px;
        margin-top: -150px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container  > h1 {
        font-size: 50px;
        margin-top: -100px;
    }

    .hero-container  > p {
        font-size: 30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}
.footer-container {
    background-color: #242424;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
}

.footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
    margin-bottom: 50px;
    font-size: 24px;
}

.footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
}

.footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
}

.footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
}

.footer-link-wrapper {
    display: flex;
}

.footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
}

.footer-link-items h2 {
    margin-bottom: 16px;
}

.footer-link-items > h2 {
    color: #fff;
}

.footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 8px;
}

.footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
}

.footer-email-form h2 {
    margin-bottom: 32px;
}

.footer-input::-webkit-input-placeholder {
    color: #b1b1b1;
}

.footer-input::placeholder {
    color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
    color: #fff;
    font-size: 24px;
}

.social-media {
    max-width: 1000px;
    width: 100%;
}

.social-media-wrap {
    display: inline-block;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    font-size: 40px;
    margin: 40px auto 0 auto;
}

.social-icons {
    display: inline-block;
    color: #fff;
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    width: 240px;
}

.social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-self: center;
    margin-bottom: 16px;
}

.website-rights {
    color: #fff;
    margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
    .footer-links {
        padding-top: 2rem;
    }

    .footer-input {
        width: 100%;
    }

    .btn {
        width: 100%;
    }
    .footer-link-wrapper {
        flex-direction: column;
    }

    .social-media-wrap {
        flex-direction: column;
    }
}
